.pinDisplay {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
}

.pinDot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: white;
  transition: background-color 0.2s;
}

.pinDot.filled {
  background: #007bff;
}

.numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-width: 300px;
  margin: 0 auto;
}

.numButton {
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
